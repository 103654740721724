.HjfcDe {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .contentNf7nc1 {
        width: 95%;
        margin: 5px;
        margin-top: 100px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .messageLink {
            background: #f3f3f3;
            border: 2px solid #f3f3f3;
            padding: 10px;
            font-weight: 200;
            color: #303030;
            text-decoration: none;
            transition: background-color 100ms ease, color 100ms ease;
            display: inline-flex;
            gap: 10px;
            margin-bottom: 30px;
            border-radius: 25px;

            &:hover {
                opacity: 0.85
            }
        }

        h2 {
            font-size: 55px;
            line-height: 66px;
            color: #303030;
            text-align: center;
            margin-bottom: 22px;
        }

        p {
            font-size: 22px;
            line-height: 30px;
            color: #303030;
            text-align: center;
            text-shadow: 0 0 30px #303030;
            max-width: 700px;
            margin: 0 auto 40px;
        }
    }

    .sec1Subtitle {
        align-self: center;
        text-align: center;
        font-size: 24px;
        margin: 5px;
    }

    .seemore {
        align-self: center;
        text-align: center;
        font-size: 14px;
        max-width: 110px;
        font-weight: 300;
        margin: 5px;
        text-transform: uppercase
    }

    .vid {
        align-self: left;
        width: 100%;
        object-fit: fill;
        height: 65vh;
    }
}

.sINCdcn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 175px;
}

.about {
    width: 100%;
    max-width: 800px;
    line-height: 42px
}

.bullet {
    width: 100%;
    max-width: 800px;
    line-height: 42px
}


.pOACn23 {
    background-color: #FFFDF7;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 10px;


    .iC9JWc {
        color: #000;
        width: 100%;
        max-width: 1200px;
        padding: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.c-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    overflow: scroll;
    margin: 10px
}

.speedSection {
    padding: 90px 0;
    background-color: #05060B;

    .container {
        max-width: 1240px;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
        display: flex;
        gap: 90px;

        .speed {
            flex: 1;
            text-align: center;

            .label {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 80px;
            }

            h3 {
                font-size: 30px;
                color: red;
                text-shadow: 0 0 20px red;
                margin-bottom: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.blue {
                    color: #303030;
                    text-shadow: 0 0 20px #303030;
                }

                img {
                    width: 80px;
                }
            }

            p {
                font-size: 22px;
                color: #303030;
                margin-bottom: 25px;
            }

            .video {
                padding: 0 20px;

                video {
                    width: 100%;
                    height: auto;
                }
            }

            .player {
                //border: 2px solid #303030-10;
                //background-color: black;
                position: relative;
                overflow: hidden;
                margin-top: 50px;
            }
        }
    }
}

.imageSection {
    padding: 122px 0;
    background: #101418;
    border-top: 2px solid #303030;

    &.alt {
        background: #090B0D;
    }

    .container {
        max-width: 1240px;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
        display: flex;
        gap: 45px;
        align-items: center;
        justify-content: space-between;

        .information {
            flex: 1;

            h3 {
                font-size: 40px;
                color: #303030;
                text-shadow: 0 0 20px #303030;
                margin-bottom: 20px;
                text-transform: uppercase;
            }

            p {
                font-size: 22px;
                line-height: 30px;
            }
        }

        .image {
            flex: 1;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.infoSection {
    padding: 200px 0;

    .information {
        text-align: center;
        max-width: 700px;
        margin: 0 auto;

        h3 {
            font-size: 40px;
            color: #303030;
            text-shadow: 0 0 20px #303030;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        p {
            color: #303030;
            font-size: 22px;
            line-height: 30px;
        }

        .callToAction {
            margin-top: 20px;
            display: inline-flex;
            gap: 20px;
        }
    }
}

.ifW9Q {
    background: #FFFDF7;
    padding: 60px 0;
    display: flex;
    justify-content: space-between;

    .n9Acw1 {
        max-width: 1240px;
        margin: 0 auto;
        width: 100%;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pc8QWHCc {
        p {
            color: #303030;
            text-transform: uppercase;
            font-size: 12px;
        }

        .pAIcnue {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            margin-bottom: 10px;
            text-transform: initial;
            color: #303030;

            img {
                width: 55px;
            }
        }
    }

    .links {
        text-align: center;

        a {
            text-decoration: underline;
            font-size: 12px;
            color: #303030;
        }

        .socials {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-bottom: 10px;

            a {

                svg path,
                svg polygon {
                    transition: fill 100ms ease, fill-opacity 100ms ease;
                }

                &:hover {

                    svg path,
                    svg polygon {
                        fill: #303030;
                        fill-opacity: 1;
                    }
                }
            }
        }
    }
}

.row {}

.column {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    margin: 10px;
}

.column-sec-1-uwdndFE {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px;
}

.rowFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 10px;
    justify-content: flex-end;
    align-content: flex-end
}

.featureSection {
    align-items: baseline;
    text-align: left;
    margin: 10px
}

.featureTitle {
    color: #fff;
    font-size: 68px;
    margin: 10px;
}

.featureText {

    font-size: 28px;
    margin: 5px;
}

.featureText2 {

    font-size: 18px;
    margin: 5px;
    font-weight: normal;
    padding-left: 200px;
    padding-right: 200px;
}

.pricingTitle {
    align-self: center;
    text-align: center;
    color: #22c55e;
    font-size: 24px;
    margin: 5px;
}

.pricingText {
    align-self: center;
    text-align: center;
    font-size: 32px;
    margin: 5px;
}

.HjfcDeTitle {
    font-size: 48px;
    margin: 0px;
    color: #fff;
    max-width: 650px;
    font-weight: normal;
}

.HjfcDeSubTitle {
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    margin: 30px;
    font-weight: normal;
}

.pepe {
    width: 100%;
    min-width: 325px;
    margin-top: 0px
}

.row-c2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row-c3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.imgCard {
    border: .25px solid #000;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    margin-left: -10%;

}

.imgCard {
    width: 100%;
    height: 100%;
}

.react-multi-carousel-list{
    position: unset !important;
  }

  .gridLanding {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    margin-top: 25px;
  }
  
  .cardLanding {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    overflow: hidden;
  }
  
  .cardLanding img {
    width: 100%;
    height: auto;
  }

  .fixedWrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
}

@media (max-width: 800px) {
    .bullet {
        text-align: center; /* Center the text */
        width: 100%; /* Take the full width */
        max-width: 345px; /* Optional: Set a max width if you want */
        margin: 0 auto; /* Center the element itself */
        display: flex;
        flex-direction: column;
        align-items: center; /* Center child elements */
        justify-content: center; /* Center in case of vertical space */
        font-size: 12px;
    }
    
    .bullet h1 {
        /* Additional styles for the h1 if needed */
    }
    
    .bullet ul, .bullet li {
        text-align: left; /* If you want the list items to be left-aligned */
        width: 100%; /* Take the full width */
    }

    .HjfcDe { 
        .sec1Subtitle {
            align-self: center;
            text-align: center;
            font-size: 16px;
            margin: 5px;
        }
    }

    .F9fFa {
        width: 80%
    }

    .rowFooter {
        justify-content: center;
        align-content: center
    }

    .callToAction {
        position: absolute;
        top: 8.5%;
        left: 0;
        z-index: 1;
    }

    .pepe {
        width: 100%;
        margin-top: 0px;
        height: 500px
    }

    .HjfcDeTitle {
        font-size: 42px;
        text-align: center;
        align-self: center;
    }

    .HjfcDeSubTitle {
        text-align: center;
        align-self: center;
    }

    .featureTitle {
        align-self: center;
        text-align: center;
        color: #74affc;
        font-size: 16px;
        margin: 5px;
    }

    .featureText {
        align-self: center;
        text-align: center;
        font-size: 18px;
        margin: 5px;
    }

    .featureText2 {

        font-size: 18px;
        margin: 5px;
        font-weight: normal;
        padding-left: 0px;
        padding-right: 0px;
    }

    .featureSection {
        align-items: center;
        text-align: center;
        margin: 10px
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0px;
    }

    .HjfcDe {
        .messageLink {}

        h2 {
            font-size: 31px !important;
            line-height: 36px !important;
        }

        p {
            font-size: 16px !important;
            line-height: 22px !important;
            margin-bottom: 20px !important;
        }
    }

    .speedSection {
        .container {
            flex-direction: column;
            gap: 45px;

            .speed {
                width: 100%;
                max-width: 700px;
                margin: 0 auto;
            }
        }
    }

    .imageSection {
        .container {
            flex-direction: column;

            h3 {
                text-align: center;
                font-size: 30px !important;
                line-height: 37px !important;
            }

            p {
                text-align: center;
                font-size: 18px !important;
            }
        }
    }

    .infoSection {
        h3 {
            font-size: 30px !important;
            line-height: 37px !important;
        }

        p {
            font-size: 18px !important;
        }
    }

    .ifW9Q {
        .n9Acw1 {
            flex-direction: column;
            gap: 20px;
            text-align: center;
            align-items: center;

            .pc8QWHCc {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 50px;
            }
        }
    }

    .videoSection {
        margin-top: 85px
    }

    .HjfcDe {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .contentNf7nc1 {
            width: 100%;
            max-width: 100%;
            padding: 10px;
            margin: 55px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

    }

    .column-sec-1-uwdndFE {
        width: 100%;
        margin-bottom: 95px;
        z-index: 2
    }

    .featureTitle {
        color: #fff;
        font-size: 68px;
        margin: 10px;
    }

    .featureText {
        font-size: 28px;
    }

    .iFei {
        font-size: 28px !important;
    }
}

.carouselItem {
    margin-right: 10px; /* Adjust the margin as needed */
  }
  
  .item {
    margin-left: -50%; /* Adjust the negative margin to show half of the next image */
  }