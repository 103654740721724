.navigation {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #FFFDF7;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(25px);

  z-index: 10;
  max-height: 200px;

  .menuButton {
    display: none;

  }

  .container {
    max-width: 100%;
    max-height: 100px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px
  }

  ul {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    list-style-type: none;
    font-weight: bold;
    transition: .25s ease-in-out;
    li {
      a {
        color: #000;
        font-size: 16px;
        text-decoration: none;
      }
      
      button {

      }
    }
  }

  .spacing {
    display: none;
  }

  .logo {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1px;
    font-size: 28px;
    color: #000;
    font-weight: 900;
    max-height: 75px;
    img {
      height: 65px;
    }
  }
}

@media (max-width: 800px) {
  .navigation {
    height: 650px;

      ul {
        width: 100%;
        max-width: 350px;
        padding: 1px;
        gap: 1px;
        margin-left: 75px;
        li {
          a {
            color: #000;
            font-size: 12px;
            text-decoration: none;
          }
          
          button {
    
          }
        }
    }

    li {
      a {
        display: block;
        padding: 2px;
        font-size: 8px;
        text-transform: uppercase;
        text-align: center;
        border: none;
        color: #fff;
      }
    }

    .menuButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      width: 45px;
      background-color: #000;
      border: none;
      border-radius: 10px;
      cursor: pointer;
    }

    .container {

    }

    &.navigationOpen {
      height: 100vh;

      ul {
        display: block;
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        max-width: 350px;
        padding: 10px;
        background-color: #000;
        li {
          a {
            display: block;
            padding: 20px;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            background-color: #000;
            border: none;
            color: #fff;

            border-radius: 5px;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
