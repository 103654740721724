html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Jua', sans-serif;
  font-weight: 400 !important;
  background-color: #FFFDF7 !important;
  font-weight: 900;
  font-size: 14px;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  margin:auto;
  padding:auto;
  overflow-x: hidden;
}